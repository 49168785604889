// RegisterScreen.js

import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
  getAuth, 
  createUserWithEmailAndPassword, 
  sendEmailVerification, 
  signInWithPopup, 
  GoogleAuthProvider,
  fetchSignInMethodsForEmail 
} from 'firebase/auth';
import Icon from '../assets/Icon.png';

// Função para obter o emoji da bandeira
const getFlagEmoji = (countryCode) => {
  if (!countryCode) return '';
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map((char) => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
};

// Lista completa de códigos de países para o WhatsApp
const countryCodes = [
  { name: 'Afeganistão', code: '+93', countryCode: 'AF' },
  { name: 'África do Sul', code: '+27', countryCode: 'ZA' },
  { name: 'Albânia', code: '+355', countryCode: 'AL' },
  { name: 'Alemanha', code: '+49', countryCode: 'DE' },
  { name: 'Argélia', code: '+213', countryCode: 'DZ' },
  { name: 'Argentina', code: '+54', countryCode: 'AR' },
  { name: 'Austrália', code: '+61', countryCode: 'AU' },
  { name: 'Áustria', code: '+43', countryCode: 'AT' },
  { name: 'Bélgica', code: '+32', countryCode: 'BE' },
  { name: 'Bolívia', code: '+591', countryCode: 'BO' },
  { name: 'Brasil', code: '+55', countryCode: 'BR' },
  { name: 'Canadá', code: '+1', countryCode: 'CA' },
  { name: 'Chile', code: '+56', countryCode: 'CL' },
  { name: 'China', code: '+86', countryCode: 'CN' },
  { name: 'Colômbia', code: '+57', countryCode: 'CO' },
  { name: 'Coreia do Sul', code: '+82', countryCode: 'KR' },
  { name: 'Dinamarca', code: '+45', countryCode: 'DK' },
  { name: 'Egito', code: '+20', countryCode: 'EG' },
  { name: 'Emirados Árabes Unidos', code: '+971', countryCode: 'AE' },
  { name: 'Espanha', code: '+34', countryCode: 'ES' },
  { name: 'Estados Unidos', code: '+1', countryCode: 'US' },
  { name: 'França', code: '+33', countryCode: 'FR' },
  { name: 'Grécia', code: '+30', countryCode: 'GR' },
  { name: 'Índia', code: '+91', countryCode: 'IN' },
  { name: 'Itália', code: '+39', countryCode: 'IT' },
  { name: 'Japão', code: '+81', countryCode: 'JP' },
  { name: 'México', code: '+52', countryCode: 'MX' },
  { name: 'Portugal', code: '+351', countryCode: 'PT' },
  { name: 'Reino Unido', code: '+44', countryCode: 'GB' },
  { name: 'Rússia', code: '+7', countryCode: 'RU' },
  { name: 'Suíça', code: '+41', countryCode: 'CH' },
  { name: 'Turquia', code: '+90', countryCode: 'TR' },
  { name: 'Uruguai', code: '+598', countryCode: 'UY' },
  { name: 'Venezuela', code: '+58', countryCode: 'VE' },
  // Adicione mais países conforme necessário
];

// Componente SVG do Logo do Google
const GoogleLogo = ({ size = 24 }) => (
  <svg viewBox="0 0 24 24" width={size} height={size} xmlns="http://www.w3.org/2000/svg">
    <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4" />
    <path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853" />
    <path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05" />
    <path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335" />
  </svg>
);

// Funções de validação (CPF, CNPJ, número de telefone e senha)
const validateCPF = (cpf) => {
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) return false;

  let sum = 0;
  let remainder;

  for (let i = 1; i <= 9; i++)
    sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);

  remainder = (sum * 10) % 11;
  if (remainder === 10 || remainder === 11) remainder = 0;
  if (remainder !== parseInt(cpf.substring(9, 10))) return false;

  sum = 0;
  for (let i = 1; i <= 10; i++)
    sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);

  remainder = (sum * 10) % 11;
  if (remainder === 10 || remainder === 11) remainder = 0;
  if (remainder !== parseInt(cpf.substring(10, 11))) return false;

  return true;
};

const validateCNPJ = (cnpj) => {
  cnpj = cnpj.replace(/[^\d]+/g, '');
  if (cnpj.length !== 14 || /^(\d)\1+$/.test(cnpj)) return false;

  let length = cnpj.length - 2;
  let numbers = cnpj.substring(0, length);
  let digits = cnpj.substring(length);

  let sum = 0;
  let pos = length - 7;

  for (let i = length; i >= 1; i--) {
    sum += numbers.charAt(length - i) * pos--;
    if (pos < 2) pos = 9;
  }

  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result !== parseInt(digits.charAt(0))) return false;

  length += 1;
  numbers = cnpj.substring(0, length);
  sum = 0;
  pos = length - 7;

  for (let i = length; i >= 1; i--) {
    sum += numbers.charAt(length - i) * pos--;
    if (pos < 2) pos = 9;
  }

  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result !== parseInt(digits.charAt(1))) return false;

  return true;
};

const validatePhoneNumber = (number) => {
  const cleanedNumber = number.replace(/\D/g, '');
  return cleanedNumber.length >= 10 && cleanedNumber.length <= 15;
};

const validatePassword = (password) => {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?#&])[A-Za-z\d@$!%*?#&]{8,}$/;
  return regex.test(password);
};

const RegisterScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = getAuth();

  const [isGoogleRegistration, setIsGoogleRegistration] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [cnpjOrCpf, setCnpjOrCpf] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [countryCode, setCountryCode] = useState('+55');
  const [acceptNotifications, setAcceptNotifications] = useState(true);
  const [error, setError] = useState({});
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('');

  const dropdownRef = useRef(null);

  // Captura o parâmetro 'plano' da URL
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const plano = params.get('plano');
    if (plano === 'atendente' || plano === 'especialista') {
      setSelectedPlan(plano);
    } else {
      // Opcional: Definir um plano padrão ou lidar com planos inválidos
      setSelectedPlan('atendente'); // ou outro comportamento desejado
    }
  }, [location]);

  // Funções para fechar o dropdown ao clicar fora
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleShowPassword = () => setShowPassword(!showPassword);
  const toggleShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  // Inicializa o estado com base em se o usuário está autenticado
  useEffect(() => {
    const checkUserRegistration = async () => {
      const user = auth.currentUser;
      if (user) {
        const idTokenResult = await user.getIdTokenResult(true);
        const claims = idTokenResult.claims;
        console.log('Claims recebidas:', claims); // Log para debugging
        if (!claims.registrationComplete) {
          setIsGoogleRegistration(true);
          setEmail(user.email);
        } else {
          navigate('/dashboard');
        }
      }
    };
    checkUserRegistration();
  }, [navigate, auth]);

  // URLs corretas com hífen
  const saveUserDataURL = 'https://southamerica-east1-iat-consulting.cloudfunctions.net/App-IAT/save-user-data';
  const updateClaimsURL = 'https://southamerica-east1-iat-consulting.cloudfunctions.net/App-IAT/update-claims';

  // Mapeamento dos planos para os links de Checkout
  const checkoutLinks = {
    atendente: 'https://buy.stripe.com/aEUeW62bA1iPbpSdQT',
    especialista: 'https://buy.stripe.com/3cs7tE7vUf9F65ycMR',
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setError({});
    setMessage('');
    setIsLoading(true);

    const newError = {};

    // Validações dos campos adicionais
    if (!companyName) {
      newError.companyName = 'Por favor, insira o nome da empresa.';
    }
    if (!cnpjOrCpf) {
      newError.cnpjOrCpf = 'Por favor, insira um CPF ou CNPJ.';
    } else {
      const cleanedCnpjOrCpf = cnpjOrCpf.replace(/[^\d]+/g, '');
      if (cleanedCnpjOrCpf.length === 11) {
        if (!validateCPF(cleanedCnpjOrCpf)) {
          newError.cnpjOrCpf = 'CPF inválido. Por favor, insira um CPF válido.';
        }
      } else if (cleanedCnpjOrCpf.length === 14) {
        if (!validateCNPJ(cleanedCnpjOrCpf)) {
          newError.cnpjOrCpf = 'CNPJ inválido. Por favor, insira um CNPJ válido.';
        }
      } else {
        newError.cnpjOrCpf = 'Por favor, insira um CPF ou CNPJ válido com o número correto de dígitos.';
      }
    }
    if (!whatsapp) {
      newError.whatsapp = 'Por favor, insira um número de WhatsApp.';
    } else if (!validatePhoneNumber(whatsapp)) {
      newError.whatsapp = 'Por favor, insira um número de WhatsApp válido.';
    }

    // Validações adicionais para registro tradicional
    if (!isGoogleRegistration) {
      if (!validatePassword(password)) {
        newError.password = 'A senha deve conter pelo menos 8 caracteres, incluindo uma letra maiúscula, uma minúscula, um número e um caractere especial.';
      }
      if (password !== confirmPassword) {
        newError.confirmPassword = 'As senhas não coincidem.';
      }
    }

    if (Object.keys(newError).length > 0) {
      setError(newError);
      setIsLoading(false);
      return;
    }

    try {
      if (isGoogleRegistration) {
        // Registro via Google
        const user = auth.currentUser;
        const idToken = await user.getIdToken();

        // Chama save-user-data
        const response = await fetch(saveUserDataURL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken,
          },
          body: JSON.stringify({
            email: user.email,
            companyName,
            cnpjOrCpf,
            whatsapp: `${countryCode}${whatsapp}`,
            acceptNotifications,
            active: true, // Assegura que 'active' está definido
          }),
        });

        const result = await response.json();

        if (!response.ok) {
          if (result.error === 'CPF ou CNPJ inválido') {
            setError({ cnpjOrCpf: 'CPF ou CNPJ inválido. Por favor, verifique e tente novamente.' });
          } else if (result.error === 'Número de WhatsApp inválido') {
            setError({ whatsapp: 'Número de WhatsApp inválido. Por favor, verifique e tente novamente.' });
          } else {
            setError({ global: result.error || 'Erro ao salvar dados do usuário' });
          }
          throw new Error(result.error || 'Erro ao salvar dados do usuário');
        }

        // Atualiza as claims
        const updateClaimsResponse = await fetch(updateClaimsURL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken,
          },
          body: JSON.stringify({
            uid: user.uid,      // Adiciona o UID do usuário
            role: 'admin',      // Define o papel como 'admin' ou ajuste conforme necessário
            active: true,       // Assegura que 'active' está definido
          }),
        });

        if (!updateClaimsResponse.ok) {
          throw new Error('Erro ao atualizar claims do usuário');
        }

        // Force a atualização do token
        await user.getIdToken(true); // Atualiza o token

        // Redirecionar para o Stripe Checkout
        const checkoutUrl = `${checkoutLinks[selectedPlan]}?prefilled_email=${encodeURIComponent(user.email)}`;
        window.location.assign(checkoutUrl);

      } else {
        // Registro tradicional

        // **Verificação se o e-mail já está em uso**
        const signInMethods = await fetchSignInMethodsForEmail(auth, email);
        console.log('Métodos de autenticação para o email:', signInMethods); // Log para debugging

        if (signInMethods.length > 0) {
          // Se o email já estiver registrado via Google, sugerir login via Google
          if (signInMethods.includes('google.com')) {
            setError({ email: 'Este e-mail está registrado com o Google. Por favor, faça login com o Google.' });
          } else {
            setError({ email: 'Este e-mail já está em uso. Tente fazer login ou use outro e-mail.' });
          }
          setIsLoading(false);
          return;
        }

        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        await sendEmailVerification(user);
        const idToken = await user.getIdToken();

        // Chama save-user-data
        const response = await fetch(saveUserDataURL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken,
          },
          body: JSON.stringify({
            email: user.email,
            companyName,
            cnpjOrCpf,
            whatsapp: `${countryCode}${whatsapp}`,
            acceptNotifications,
            active: true, // Assegura que 'active' está definido
          }),
        });

        const result = await response.json();

        if (!response.ok) {
          if (result.error === 'CPF ou CNPJ inválido') {
            setError({ cnpjOrCpf: 'CPF ou CNPJ inválido. Por favor, verifique e tente novamente.' });
          } else if (result.error === 'Número de WhatsApp inválido') {
            setError({ whatsapp: 'Número de WhatsApp inválido. Por favor, verifique e tente novamente.' });
          } else {
            setError({ global: result.error || 'Erro ao salvar dados do usuário' });
          }
          throw new Error(result.error || 'Erro ao salvar dados do usuário');
        }

        // Atualiza as claims
        const updateClaimsResponse = await fetch(updateClaimsURL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken,
          },
          body: JSON.stringify({
            uid: user.uid,      // Adiciona o UID do usuário
            role: 'admin',      // Define o papel como 'admin' ou ajuste conforme necessário
            active: true,       // Assegura que 'active' está definido
          }),
        });

        if (!updateClaimsResponse.ok) {
          throw new Error('Erro ao atualizar claims do usuário');
        }

        // Force a atualização do token
        await user.getIdToken(true); // Atualiza o token

        // Redirecionar para o Stripe Checkout
        const checkoutUrl = `${checkoutLinks[selectedPlan]}?prefilled_email=${encodeURIComponent(user.email)}`;
        window.location.assign(checkoutUrl);
      }

    } catch (err) {
      console.error('Erro ao fazer o cadastro:', err);
      
      // Verifica se o erro é 'auth/email-already-in-use'
      if (err.code === 'auth/email-already-in-use') {
        setError({ email: 'Este e-mail já está em uso. Tente fazer login ou use outro e-mail.' });
      } else {
        if (err.message) {
          setError({ global: err.message });
        } else {
          setError({ global: 'Erro ao fazer o cadastro. Tente novamente.' });
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      setIsGoogleRegistration(true); // Define isGoogleRegistration como true após o sign-in

      // Opcional: Verificar se o usuário já está cadastrado e redirecionar
      const user = result.user;
      const idToken = await user.getIdToken();

      // Chama save-user-data
      const response = await fetch(saveUserDataURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken,
        },
        body: JSON.stringify({
          email: user.email,
          companyName,
          cnpjOrCpf,
          whatsapp: `${countryCode}${whatsapp}`,
          acceptNotifications,
          active: true, // Assegura que 'active' está definido
        }),
      });

      const resultData = await response.json();

      if (!response.ok) {
        if (resultData.error === 'CPF ou CNPJ inválido') {
          setError({ cnpjOrCpf: 'CPF ou CNPJ inválido. Por favor, verifique e tente novamente.' });
        } else if (resultData.error === 'Número de WhatsApp inválido') {
          setError({ whatsapp: 'Número de WhatsApp inválido. Por favor, verifique e tente novamente.' });
        } else {
          setError({ global: resultData.error || 'Erro ao salvar dados do usuário' });
        }
        throw new Error(resultData.error || 'Erro ao salvar dados do usuário');
      }

      // Atualiza as claims
      const updateClaimsResponse = await fetch(updateClaimsURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken,
        },
        body: JSON.stringify({
          uid: user.uid,      // Adiciona o UID do usuário
          role: 'admin',      // Define o papel como 'admin' ou ajuste conforme necessário
          active: true,       // Assegura que 'active' está definido
        }),
      });

      if (!updateClaimsResponse.ok) {
        throw new Error('Erro ao atualizar claims do usuário');
      }

      // Force a atualização do token
      await user.getIdToken(true); // Atualiza o token

      // Redirecionar para o Stripe Checkout
      const checkoutUrl = `${checkoutLinks[selectedPlan]}?prefilled_email=${encodeURIComponent(user.email)}`;
      window.location.assign(checkoutUrl);

    } catch (err) {
      console.error('Erro ao fazer login com Google:', err);
      if (err.message) {
        setError({ global: err.message });
      } else {
        setError({ global: 'Erro ao fazer login com Google. Tente novamente.' });
      }
    }
  };

  const handleBackToLogin = () => {
    navigate('/login');
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-[#242424] p-3">
      <div className="w-full max-w-lg bg-white rounded-lg shadow-md p-5 relative">
        
        <button
          onClick={handleBackToLogin}
          className="absolute top-4 left-4 text-gray-500 hover:text-gray-700 focus:outline-none"
          aria-label="Voltar para login"
        >
          <i className="fas fa-arrow-left text-xl"></i>
        </button>

        <div className="flex justify-center mb-1">
          <img src={Icon} alt="Logo" width={48} height={48} />
        </div>

        {message && (
          <div className="text-green-500 text-xs mb-2">
            {message}
          </div>
        )}

        <form onSubmit={handleRegister} className="space-y-1">
          {error.global && (
            <div className="text-red-500 text-xs mb-2">
              {error.global}
            </div>
          )}

          {/* Campo Oculto para o Plano Selecionado */}
          <input type="hidden" id="selectedPlan" value={selectedPlan} />

          <div>
            <label
              htmlFor="companyName"
              className="block text-xs font-medium text-gray-700 mb-1"
            >
              Nome da Empresa
            </label>
            <input
              type="text"
              id="companyName"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              required
              className={`w-full p-2 border ${
                error.companyName ? 'border-red-500' : 'border-gray-300'
              } rounded text-sm`}
            />
            {error.companyName && (
              <div className="text-red-500 text-xs mt-1">
                {error.companyName}
              </div>
            )}
          </div>

          <div>
            <label
              htmlFor="cnpjOrCpf"
              className="block text-xs font-medium text-gray-700 mb-1"
            >
              CNPJ ou CPF
            </label>
            <input
              type="text"
              id="cnpjOrCpf"
              value={cnpjOrCpf}
              onChange={(e) => setCnpjOrCpf(e.target.value)}
              required
              className={`w-full p-2 border ${
                error.cnpjOrCpf ? 'border-red-500' : 'border-gray-300'
              } rounded text-sm`}
            />
            {error.cnpjOrCpf && (
              <div className="text-red-500 text-xs mt-1">
                {error.cnpjOrCpf}
              </div>
            )}
          </div>

          <div>
            <label
              htmlFor="whatsapp"
              className="block text-xs font-medium text-gray-700 mb-1"
            >
              WhatsApp
            </label>
            <div className="flex">
              <div className="relative w-32" ref={dropdownRef}>
                <button
                  type="button"
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  className={`w-full p-2 border ${
                    error.whatsapp ? 'border-red-500' : 'border-gray-300'
                  } rounded-l bg-white text-sm flex items-center justify-between`}
                >
                  <span>
                    {getFlagEmoji(
                      countryCodes.find((c) => c.code === countryCode)?.countryCode
                    )}{' '}
                    {countryCode}
                  </span>
                  <svg
                    className="w-4 h-4 ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    {isDropdownOpen ? (
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 15l7-7 7 7"
                      />
                    ) : (
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    )}
                  </svg>
                </button>
                {isDropdownOpen && (
                  <div
                    className="absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded shadow-lg max-h-40 overflow-auto text-sm"
                    style={{ top: '100%' }}
                  >
                    {countryCodes.map((country) => (
                      <div
                        key={country.code}
                        onClick={() => {
                          setCountryCode(country.code);
                          setIsDropdownOpen(false);
                        }}
                        className="cursor-pointer px-2 py-1 hover:bg-gray-100 flex items-center"
                      >
                        <span className="mr-2">
                          {getFlagEmoji(country.countryCode)}
                        </span>
                        <span>{country.code}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <input
                type="text"
                id="whatsapp"
                value={whatsapp}
                onChange={(e) => setWhatsapp(e.target.value)}
                placeholder="DDD + número"
                required
                className={`w-full p-2 border ${
                  error.whatsapp ? 'border-red-500' : 'border-gray-300'
                } rounded-r text-sm`}
              />
            </div>
            {error.whatsapp && (
              <div className="text-red-500 text-xs mt-1">
                {error.whatsapp}
              </div>
            )}
          </div>

          {/* Campo de E-mail Condicional */}
          {!isGoogleRegistration && (
            <div>
              <label
                htmlFor="email"
                className="block text-xs font-medium text-gray-700 mb-1"
              >
                E-mail
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className={`w-full p-2 border ${
                  error.email ? 'border-red-500' : 'border-gray-300'
                } rounded text-sm`}
              />
              {error.email && (
                <div className="text-red-500 text-xs mt-1">
                  {error.email}
                </div>
              )}
            </div>
          )}

          {/* Campos de Senha Condicional */}
          {!isGoogleRegistration && (
            <>
              <div>
                <label
                  htmlFor="password"
                  className="block text-xs font-medium text-gray-700 mb-1"
                >
                  Senha
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className={`w-full p-2 border ${
                      error.password ? 'border-red-500' : 'border-gray-300'
                    } rounded text-sm`}
                  />
                  <button
                    type="button"
                    className="absolute right-2 top-2 text-sm"
                    onClick={toggleShowPassword}
                  >
                    {showPassword ? (
                      <i className="fas fa-eye-slash text-gray-500" style={{ fontSize: '20px' }}></i>
                    ) : (
                      <i className="fas fa-eye text-gray-500" style={{ fontSize: '20px' }}></i>
                    )}
                  </button>
                </div>
                {error.password && (
                  <div className="text-red-500 text-xs mt-1">
                    {error.password}
                  </div>
                )}
              </div>

              <div>
                <label
                  htmlFor="confirmPassword"
                  className="block text-xs font-medium text-gray-700 mb-1"
                >
                  Confirmar Senha
                </label>
                <div className="relative">
                  <input
                    type={showConfirmPassword ? 'text' : 'password'}
                    id="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    className={`w-full p-2 border ${
                      error.confirmPassword ? 'border-red-500' : 'border-gray-300'
                    } rounded text-sm`}
                  />
                  <button
                    type="button"
                    className="absolute right-2 top-2 text-sm"
                    onClick={toggleShowConfirmPassword}
                  >
                    {showConfirmPassword ? (
                      <i className="fas fa-eye-slash text-gray-500" style={{ fontSize: '20px' }}></i>
                    ) : (
                      <i className="fas fa-eye text-gray-500" style={{ fontSize: '20px' }}></i>
                    )}
                  </button>
                </div>
                {error.confirmPassword && (
                  <div className="text-red-500 text-xs mt-1">
                    {error.confirmPassword}
                  </div>
                )}
              </div>
            </>
          )}

          <div className="flex items-center text-sm">
            <input
              type="checkbox"
              id="acceptNotifications"
              checked={acceptNotifications}
              onChange={(e) => setAcceptNotifications(e.target.checked)}
              className="mr-2"
            />
            <label
              htmlFor="acceptNotifications"
              className="text-gray-700"
            >
              Aceito receber notificações pelo WhatsApp
            </label>
          </div>

          <button
            type="submit"
            className="w-full bg-[#f1c915] hover:bg-[#e0b714] text-white py-2 rounded text-sm"
          >
            {isLoading ? 'Cadastrando...' : 'Cadastrar'}
          </button>
        </form>

        {!isGoogleRegistration && (
          <div className="mt-3 relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white text-gray-500">ou</span>
            </div>
          </div>
        )}

        {!isGoogleRegistration && (
          <div className="mt-3">
            <button
              onClick={handleGoogleSignIn}
              className="w-full flex items-center justify-center space-x-2 border border-gray-300 py-2 rounded"
            >
              <GoogleLogo size={24} />
              <span>Registrar com o Google</span>
            </button>
          </div>
        )}
        
        <div className="mt-6 text-center text-xs text-gray-500">
          <a href="https://www.iatconsulting.com.br/termos-condicoes" target="_blank" rel="noopener noreferrer" className="hover:underline">Termos de uso</a>
          {' | '}
          <a href="https://www.iatconsulting.com.br/politica-privacidade" target="_blank" rel="noopener noreferrer" className="hover:underline">Política de privacidade</a>
        </div>
      </div>
    </div>
  );
};

export default RegisterScreen;
