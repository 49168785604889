// src/components/WhatsAppConfig.js

import React, { useState, useEffect } from 'react';
import { Edit, MessageSquare, Plus, Loader2 } from 'lucide-react'; // Importação dos ícones necessários
import { getAuth } from 'firebase/auth'; // Importar getAuth para obter o token

const WhatsAppConfig = ({ setCurrentView }) => {
  // Estados para armazenar as configurações do WhatsApp
  const [cadastro, setCadastro] = useState({
    nome: '',
    numeroConectado: '',
    limitesMensagens: '',
    statusNumero: '',
    wabaId: '',
    verificacaoEmpresarial: '',
    statusConta: '',
    linhaCredito: '',
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [dataFetched, setDataFetched] = useState(false); // Estado para controlar se os dados foram buscados

  // --------------------------------------------
  // NOVO: armazenar o code que o FB retorna.
  // --------------------------------------------
  const [fbCode, setFbCode] = useState(null);

  // Estilos compartilhados para inputs (campos "desabilitados")
  const sharedStyles = {
    backgroundColor: '#333',
    color: '#fff',
    padding: '12px',
    borderRadius: '6px',
    border: '1px solid #555',
    width: '100%',
    fontSize: '14px',
    cursor: 'not-allowed',
    boxSizing: 'border-box',
    height: '44px', // Garantir altura consistente
  };

  // Função para obter o token do Firebase (para chamadas autenticadas ao backend)
  const getFirebaseToken = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      return await user.getIdToken();
    }
    throw new Error('Usuário não autenticado.');
  };

  // -------------------------------------------------------------------------
  // Função para buscar as configurações do WhatsApp do backend
  // (Ajuste o endpoint abaixo se necessário; você mencionou /get-whatsapp-config)
  // -------------------------------------------------------------------------
  const fetchWhatsAppConfig = async () => {
    try {
      setLoading(true);
      const firebaseToken = await getFirebaseToken();

      // Ajuste a URL caso no seu backend seja /get-whatsapp-config
      const response = await fetch('https://app.iatconsulting.com.br/settings', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${firebaseToken}`, // Incluir o token no cabeçalho
        },
      });

      const data = await response.json();
      if (response.ok) {
        setCadastro(data.whatsappConfig);
        setMessage({ type: 'success', text: 'Configurações carregadas com sucesso!' });
        setDataFetched(true);
      } else {
        setMessage({ type: 'error', text: data.error || 'Erro ao carregar as configurações.' });
      }
    } catch (error) {
      console.error('Erro:', error);
      setMessage({ type: 'error', text: 'Ocorreu um erro ao carregar as configurações.' });
    } finally {
      setLoading(false);
    }
  };

  // ------------------------------------------------------------
  // FUNÇÃO PRINCIPAL: registrar número na Cloud API
  // - Agora recebe CODE + phoneNumberId
  // ------------------------------------------------------------
  const registrarNumeroNaCloudAPI = async (code, phoneNumberId) => {
    try {
      setLoading(true);
      const firebaseToken = await getFirebaseToken(); // Obter token do usuário autenticado

      const response = await fetch(
        'https://southamerica-east1-iat-consulting.cloudfunctions.net/App-IAT/processar-cadastro',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${firebaseToken}`,
          },
          body: JSON.stringify({
            code: code,              // Enviando code do Facebook
            phoneNumberId: phoneNumberId,
            pin: '132124',           // PIN de 6 dígitos (exemplo)
          }),
        }
      );

      const data = await response.json();
      if (response.ok) {
        setMessage({
          type: 'success',
          text: data.message || 'Número registrado com sucesso na Cloud API!',
        });
        // Se quiser, recarregue as configs para atualizar status
        await fetchWhatsAppConfig();
      } else {
        setMessage({
          type: 'error',
          text: data.error || 'Falha ao registrar o número na Cloud API.',
        });
      }
    } catch (error) {
      console.error('Erro ao registrar número:', error);
      setMessage({ type: 'error', text: 'Erro ao registrar número.' });
    } finally {
      setLoading(false);
    }
  };

  // ----------------------------------------------
  // Função para iniciar o fluxo de Cadastro
  // ----------------------------------------------
  const iniciarCadastroIncorporado = () => {
    launchWhatsAppSignup();
  };

  // ----------------------------------------------
  // Função para iniciar o fluxo de Embedded Signup
  // ----------------------------------------------
  const launchWhatsAppSignup = () => {
    const configId = '1237469174054721'; // Substitua pelo seu configId

    // Verificar se o SDK do Facebook está carregado
    if (window.FB) {
      window.FB.login(
        (response) => {
          if (response.authResponse) {
            // A partir do response, obtemos o code
            const code = response.authResponse.code;
            console.log('Código recebido (FB.login):', code);
            // Guardar esse code num state
            setFbCode(code);

            // ATENÇÃO: A parte que realmente finaliza o "WhatsApp Embedded Flow"
            // e pega phoneNumberId é escutada no 'useEffect' -> messageListener
            // (data.event === 'FINISH')
            // Então, só armazenamos o code aqui.
          } else {
            setMessage({ type: 'error', text: 'Falha na autenticação.' });
          }
        },
        {
          config_id: configId,
          response_type: 'code',
          override_default_response_type: true,
          extras: {
            setup: {},
            featureType: '',
            sessionInfoVersion: '3',
          },
        }
      );
    } else {
      setMessage({
        type: 'error',
        text: 'SDK do Facebook não carregado. Por favor, tente novamente mais tarde.',
      });
      console.error('SDK do Facebook não carregado.');
    }
  };

  // ------------------------------------------------------------------------------
  // useEffect que escuta mensagens do Facebook (Embedded Signup) e finaliza o fluxo
  // ------------------------------------------------------------------------------
  useEffect(() => {
    const messageListener = (event) => {
      // Verificar a origem para segurança conforme a documentação
      const allowedOrigins = ['https://www.facebook.com', 'https://web.facebook.com'];
      if (!allowedOrigins.includes(event.origin)) {
        return;
      }

      try {
        const data = JSON.parse(event.data);
        if (data.type === 'WA_EMBEDDED_SIGNUP') {
          if (data.event === 'FINISH') {
            const { phone_number_id, waba_id } = data.data;
            console.log(
              'ID do Número de Telefone:',
              phone_number_id,
              'ID da Conta WABA:',
              waba_id
            );

            // Agora chamamos a função de registro
            // passando o code que capturamos no FB.login + phone_number_id
            if (!fbCode) {
              setMessage({
                type: 'error',
                text: 'Não foi possível registrar: code não encontrado. Tente novamente.',
              });
              return;
            }
            registrarNumeroNaCloudAPI(fbCode, phone_number_id);
          } else if (data.event === 'CANCEL') {
            const { current_step } = data.data;
            setMessage({
              type: 'error',
              text: `Cadastro cancelado na etapa: ${current_step}`,
            });
          } else if (data.event === 'ERROR') {
            const { error_message } = data.data;
            setMessage({
              type: 'error',
              text: `Erro no cadastro: ${error_message}`,
            });
          }
        }
      } catch (error) {
        console.error('Erro ao processar a mensagem do fluxo:', error);
      }
    };

    window.addEventListener('message', messageListener, false);

    return () => {
      window.removeEventListener('message', messageListener, false);
    };
  }, [fbCode]);

  // ------------------------------------------------------------
  // Função para editar o perfil do WhatsApp (exemplo)
  // ------------------------------------------------------------
  const handleEditarPerfil = () => {
    // Implementar a lógica para editar o perfil do WhatsApp
    console.log('Editar Perfil clicado');
  };

  // ------------------------------------------------------------
  // Função para redirecionar para Modelos de Mensagem
  // ------------------------------------------------------------
  const handleModelosMensagem = () => {
    setCurrentView('templateConfig');
    console.log('Redirecionando para TemplateConfig');
  };

  // ------------------------------------------------------------
  // Renderização do componente
  // ------------------------------------------------------------
  return (
    <div
      style={{
        backgroundColor: '#242424',
        color: '#fff',
        padding: '30px',
        borderRadius: '12px',
        width: '100%',
        maxWidth: '1200px',
        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.5)',
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
        margin: '0 auto',
      }}
    >
      {/* Cabeçalho com botão "Adicionar" */}
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <button
          onClick={iniciarCadastroIncorporado}
          style={{
            backgroundColor: '#242424',
            color: '#e5e7eb',
            padding: '10px 20px',
            border: '1px solid #555',
            borderRadius: '6px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            fontSize: '16px',
            transition: 'background-color 0.3s, border 0.3s',
          }}
          aria-label="Adicionar Configuração"
        >
          <Plus size={20} />
          Adicionar
        </button>
      </div>

      {/* Cabeçalho de Seção */}
      <h2 style={{ textAlign: 'center', fontSize: '16px', margin: '0' }}>
        Conta do WhatsApp
      </h2>

      {/* Formulário de Configuração (Campos Não Editáveis) */}
      <form style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        {/* Primeira Linha de Campos */}
        <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
          {/* Nome */}
          <div style={{ flex: '1 1 22%', minWidth: '200px' }}>
            <label
              htmlFor="nome"
              style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}
            >
              Nome
            </label>
            <input
              type="text"
              id="nome"
              placeholder={loading && !dataFetched ? 'Carregando...' : 'Nome não disponível'}
              value={cadastro.nome || ''}
              disabled
              style={sharedStyles}
            />
          </div>

          {/* Número Conectado */}
          <div style={{ flex: '1 1 22%', minWidth: '200px' }}>
            <label
              htmlFor="numeroConectado"
              style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}
            >
              Número Conectado
            </label>
            <input
              type="tel"
              id="numeroConectado"
              placeholder={loading && !dataFetched ? 'Carregando...' : 'Número não disponível'}
              value={cadastro.numeroConectado || ''}
              disabled
              style={sharedStyles}
            />
          </div>

          {/* Limites de Mensagens */}
          <div style={{ flex: '1 1 22%', minWidth: '200px' }}>
            <label
              htmlFor="limitesMensagens"
              style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}
            >
              Limites de Mensagens
            </label>
            <input
              type="number"
              id="limitesMensagens"
              placeholder={loading && !dataFetched ? 'Carregando...' : 'Limite não disponível'}
              value={cadastro.limitesMensagens || ''}
              disabled
              min="0"
              style={sharedStyles}
            />
          </div>

          {/* Status do Número */}
          <div style={{ flex: '1 1 22%', minWidth: '200px' }}>
            <label
              htmlFor="statusNumero"
              style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}
            >
              Status do Número
            </label>
            <input
              type="text"
              id="statusNumero"
              placeholder={
                loading && !dataFetched ? 'Carregando...' : 'Status do Número não disponível'
              }
              value={cadastro.statusNumero || ''}
              disabled
              style={sharedStyles}
            />
          </div>
        </div>

        {/* Segunda Linha de Campos */}
        <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
          {/* ID da Conta (wabaId) */}
          <div style={{ flex: '1 1 22%', minWidth: '200px' }}>
            <label
              htmlFor="wabaId"
              style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}
            >
              ID da Conta (WABA)
            </label>
            <input
              type="text"
              id="wabaId"
              placeholder={loading && !dataFetched ? 'Carregando...' : 'ID não disponível'}
              value={cadastro.wabaId || ''}
              disabled
              style={sharedStyles}
            />
          </div>

          {/* Verificação Empresarial */}
          <div style={{ flex: '1 1 22%', minWidth: '200px' }}>
            <label
              htmlFor="verificacaoEmpresarial"
              style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}
            >
              Verificação Empresarial
            </label>
            <input
              type="text"
              id="verificacaoEmpresarial"
              placeholder={
                loading && !dataFetched
                  ? 'Carregando...'
                  : 'Verificação Empresarial não disponível'
              }
              value={cadastro.verificacaoEmpresarial || ''}
              disabled
              style={sharedStyles}
            />
          </div>

          {/* Status da Conta */}
          <div style={{ flex: '1 1 22%', minWidth: '200px' }}>
            <label
              htmlFor="statusConta"
              style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}
            >
              Status da Conta
            </label>
            <input
              type="text"
              id="statusConta"
              placeholder={loading && !dataFetched ? 'Carregando...' : 'Status da Conta não disponível'}
              value={cadastro.statusConta || ''}
              disabled
              style={sharedStyles}
            />
          </div>

          {/* Linha de Crédito */}
          <div style={{ flex: '1 1 22%', minWidth: '200px' }}>
            <label
              htmlFor="linhaCredito"
              style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}
            >
              Linha de Crédito
            </label>
            <input
              type="text"
              id="linhaCredito"
              placeholder={
                loading && !dataFetched ? 'Carregando...' : 'Linha de crédito não disponível'
              }
              value={cadastro.linhaCredito || ''}
              disabled
              style={sharedStyles}
            />
          </div>
        </div>

        {/* Botões Adicionais (Editar Perfil, Modelos de Mensagem, etc.) */}
        <div style={{ display: 'flex', gap: '20px', justifyContent: 'center' }}>
          {/* Botão: Editar Perfil */}
          <button
            type="button"
            onClick={handleEditarPerfil}
            style={{
              backgroundColor: '#242424',
              color: '#e5e7eb',
              padding: '10px 20px',
              border: '1px solid #555',
              borderRadius: '6px',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              fontSize: '14px',
              transition: 'background-color 0.3s, border 0.3s',
              width: '220px',
            }}
            aria-label="Editar Perfil"
          >
            <Edit size={20} />
            Editar Perfil
          </button>

          {/* Botão: Modelos de Mensagem */}
          <button
            type="button"
            onClick={handleModelosMensagem}
            style={{
              backgroundColor: '#242424',
              color: '#e5e7eb',
              padding: '10px 20px',
              border: '1px solid #555',
              borderRadius: '6px',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              fontSize: '14px',
              transition: 'background-color 0.3s, border 0.3s',
              width: '220px',
            }}
            aria-label="Modelos de Mensagem"
          >
            <MessageSquare size={20} />
            Modelos de Mensagem
          </button>
        </div>
      </form>

      {/* Mensagem de Feedback (Carregando ou Erro/Success) */}
      {loading && (
        <div
          style={{
            textAlign: 'center',
            fontSize: '14px',
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Loader2 className="loader" size={20} />
          <span style={{ marginLeft: '10px' }}>Processando...</span>
        </div>
      )}

      {message && (
        <div
          role="alert"
          aria-live="assertive"
          style={{
            color: message.type === 'error' ? '#ff6b6b' : '#4caf50',
            fontSize: '14px',
            textAlign: 'center',
            marginTop: '10px',
          }}
        >
          {message.text}
        </div>
      )}
    </div>
  );
};

export default WhatsAppConfig;
